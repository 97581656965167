<template>
    <div class="section text-center form-contact">
        <div class="container">
            <div class="row">
                <div class="col-md-12 ml-auto mr-auto">
                    <form class="contact-form">
                        <div class="row">
                            <div class="col-md-6 ">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <LottieAnimation style="height: 40px" :animation-data="IndividualJSON"
                                            :auto-play="false" :loop="true" :speed="1" :ref="anim[0]" />
                                    </div>
                                    <label class="ml-4 mb-1 mt-2">Nombre</label>
                                </div>
                                <div class="input-group">
                                    <input type="text" @focusin="doAnim(0)" @focusout="endAnim(0)" id="name"
                                        class="form-control" placeholder="Nombre">
                                </div>
                            </div>
                            <div class="col-md-6">

                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <LottieAnimation style="height: 40px" :animation-data="MailJSON" :auto-play="false"
                                            :loop="true" :speed="0.5" :ref="anim[1]" />
                                    </div>
                                    <label class="ml-4 mb-1 mt-2">Correo</label>
                                </div>
                                <div class="input-group">
                                    <input type="text" @focusin="doAnim(1)" @focusout="endAnim(1)" id="correo"
                                        class="form-control" placeholder="Correo">
                                </div>
                            </div>
                        </div>
                        <div class="input-group mt-4">
                            <div class="input-group-prepend">
                                <LottieAnimation style="height: 40px" :animation-data="ChatJSON" :auto-play="false"
                                    :loop="true" :speed="0.5" :ref="anim[2]" />
                            </div>
                            <label class="ml-4 mb-1 mt-2">Mensaje</label>
                        </div>

                        <textarea @focusin="doAnim(2)" @focusout="endAnim(2)" class="form-control" id="description" rows="4"
                            placeholder="¿Qué nos quieres decir?"></textarea>
                        <div class="row">
                            <div class="col-md-6 mt-5">
                                <button type="button" class="btn btn-default btn-lg btn-fill">
                                    Enviar
                                    mensaje</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { LottieAnimation } from "lottie-web-vue"
import { ref } from "vue"

import IndividualJSON from "../../assets/lottie/Individual.json"
import MailJSON from "../../assets/lottie/Mail.json"
import ChatJSON from "../../assets/lottie/Chat.json"

import "../../assets/css/contact-component.css";

let anim = [ref(), ref(), ref()];

const doAnim = (index) => anim[index].value.play();
const endAnim = (index) => anim[index].value.stop();


</script>