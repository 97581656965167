<template>
    <nav>
        <img src="../../assets/logoNavbar.png" alt="Logo" class="logoImg">

        <h1 class="logo">Ferretería Magu</h1>
        <ul>
            <li>Quienes somos</li>
            <li>Tienda</li>
            <li>Plataforma</li>
            <li>Contacto</li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'NavbarComponent',
    data() {
        return {
            msg: 'Welcome to Your Vue.js App'
        }
    }
}
</script>