<template>
    <section class="platform">
        <SectionTitle :centered=true title="Plataforma B2B" description="" />
        <div class="row">

            <div class="col-md-6">
                <div class="platform-image">
                    <img src="../../assets/platform.jpg" alt="Plataforma de ventas">
                </div>
            </div>
            <div class="col-md-6">
                <div class="platform-content">
                    <LottieAnimation style="height: 200px; margin-top: -100px" :animation-data="ToolsJSON" :auto-play="true"
                        :loop="true" :speed="0.5" :ref="anim" />
                    <h3> La plataforma que te permite optimizar tu trabajo.</h3>
                    <p class="px-4 pt-2">Si ya eres cliente puedes ingresar con tu usuario y contraseña y aprovechar nuestro
                        sistema para
                        realizar tus cotizaciones de manera más rápida y sencilla.
                    </p>
                    <div class="platform-buttons">
                        <button @click="goToPlatform" type="button" class="btn btn-default btn-lg btn-fill mt-4">
                            Ir a la plataforma</button>
                    </div>
                </div>
            </div>



        </div>
    </section>
</template>

<script setup>
import SectionTitle from '../General/SectionTitle.vue';
import "../../assets/css/platform-component.css";
import { LottieAnimation } from "lottie-web-vue"
import { ref } from "vue"


import ToolsJSON from "../../assets/lottie/tools.json"

let anim = ref();



function goToPlatform() {
    window.location = `http://distribucionesmagu.dyndns.org:1945/b2b/index.php`;
}


</script>