<template>
    <section class="shop">
    <div class="row">
        <div class="col-md-6">
            <div class="shop-content">
               <span>
                <h3> ¿No quieres salir de casa? </h3>
                <p class="px-4 pt-2"> 
                    Puedes realizar tus compras desde la comodidad de tu hogar, solo debes ingresar a nuestra tienda online y realizar tu pedido. 
                </p>
                <img src="../../assets/store.png" alt="Tienda online">
            </span>
                <div class="shop-buttons">
                    <button @click="goToPlatform" type="button" class="btn btn-default btn-lg btn-fill mt-custom">
                                    Ir a la tienda</button>
                </div>
            </div>
        </div>
    
        <div class="col-md-6">
            <LottieAnimation style="height: 500px;" :animation-data="ShopJSON" :auto-play="true"
                                    :loop="true" :speed="0.5" :ref="anim" />
        </div>


    </div>
    </section>
</template>

<script setup>
import { LottieAnimation } from "lottie-web-vue"
import { ref } from "vue"


import ShopJSON from "../../assets/lottie/Shop.json"

let anim = ref();


import "../../assets/css/store-component.css";

function goToPlatform() {
    window.location = `https://www.linio.com.mx/s/ma-guadalupe-chavez-cardiel`;
}

</script>