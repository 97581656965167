<template>
  <HeroComponent />
  <AboutComponent />
  <StoreComponet />
  <NavbarComponent />
  <PlatformComponent />
  <ContactComponent />
</template>

<script>
import NavbarComponent from './components/Header/NavbarComponent.vue';
import HeroComponent from './components/Header/HeroComponent.vue'
import PlatformComponent from './components/Platform/PlatformComponent.vue';
import ContactComponent from './components/Contact/ContactComponent.vue';
import AboutComponent from './components/AboutUs/AboutComponent.vue';
import StoreComponet from './components/Store/StoreComponent.vue';

export default {
  name: 'App',
  components: {
    NavbarComponent,
    HeroComponent,
    ContactComponent,
    PlatformComponent,
    AboutComponent,
    StoreComponet
  }
}
</script>

<style>
@font-face {
  font-family: Brandon;
  src: url("./assets/Fonts/Brandon.otf");
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-family: sans-serif;
  overflow-x: hidden;
}

#app h2 {
  font-size: 36px;
  font-family: Brandon;
  font-weight: 900;
}
</style>
