<template>
    <div class="hero">
        <NavbarComponent />
        <section class="hero-main">
            <span class="hero-text">
                <h1>Herramienta de calidad a los mejores precios</h1>
                <p>
                    Somos tu destino confiable para herramientas de calidad a precios imbatibles. Conoce nuestra amplia selección de
                    herramientas de alta calidad que se adaptan a tus necesidades y presupuesto.
                </p>
                <button type="button" @click="goToPlatform" class="btn btn-default btn-lg btn-fill mt-custom mx-auto">
                                    Ir a la plataforma</button>
            </span>
        </section>
    </div>
</template>

<script>
import "../../assets/css/hero-component.css";
import NavbarComponent from "./NavbarComponent.vue";
export default {
    name: 'HeroComponent',
    data() {
        return {
            msg: 'Welcome to Your Vue.js App'
        }
    },
    components: {
        NavbarComponent
    },
    methods: {
        goToPlatform() {
            window.location = `http://distribucionesmagu.dyndns.org:1945/b2b/index.php`;
        }
    }
}
</script>