<template>
    <section class="contact">
        <SectionTitle title="Contáctanos" description="Llamanos o envianos un mensaje y encontremos lo que necesitas para tu nuevo proyecto." />
        
        <div class="container">
            <div class="row">
                <div class="col-4 info">
                    <div class="map-container">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9789.706730772863!2d-104.6278519762144!3d24.033255281239647!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x869bb7a252e82c4d%3A0x2320567166f4f99e!2sMagu%20Ferreter%C3%ADa!5e0!3m2!1ses-419!2sfr!4v1697783338671!5m2!1ses-419!2sfr"
                            width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <section class="contact-info">
                        <p>@maguferreteria</p>
                        <p>ventas@magudistribuciones.com</p>
                        <p>Tel: 618 158 6280</p>
                        <p> Masurio 611, Luis Echeverría, 34250 Durango, Dgo. Mexico</p>
                    </section>

                </div>
                <div class="col-8 form">
                    <p>
                        <FormContactComponent />
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import "../../assets/css/contact-component.css";
import FormContactComponent from "./FormContactComponent.vue";
import SectionTitle from "../General/SectionTitle.vue";


export default {
    name: 'ContactComponent',
    components: {
        FormContactComponent, 
        SectionTitle
        
    }
}
</script>